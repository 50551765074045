import { useEffect, useRef } from 'react';

/**
 * React hook for using calling a function on an interval.
 * @param {function} callback - Interval callback
 * @param {Number} delay - The interval delay
 */
export function useInterval(callback, delay)
{
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() =>
  {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() =>
  {
    function tick()
    {
      savedCallback.current();
    }
    if (delay !== null)
    {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * Return a human readable string of the number of bytes.
 * @param {Number} bytes - Number of bytes
 * @param {boolean} si - Use the metric system
 * @return {string} Human readable version of the number of bytes
 */
export function humanFileSize(bytes, si)
{
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh)
  {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do
  {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

const Utils = { useInterval, humanFileSize };
export default Utils;


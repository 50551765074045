import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {
  Link as RouterLink,
  Switch,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import StreamPlayer from './StreamPlayer';
import Client from './Client';
import { useInterval } from './Utils';

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    paddingLeft: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const useStyles = makeStyles(styles);

function StreamItem(props)
{
  const streamLink = '/stream/' + props.stream;
  return (
    <ListItem button component={RouterLink} to={streamLink}>
      <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
      <ListItemText>{props.stream}</ListItemText>
    </ListItem>
  );
}

StreamItem.propTypes = {
  stream: PropTypes.string,
};

function StreamList()
{
  const classes = useStyles();
  const [streams, setStreams] = useState([]);

  const updateStreams = useCallback(() =>
  {
    Client.getStreams(streams => setStreams(streams));
  }, [setStreams]);

  useInterval(updateStreams, 2000);

  useEffect(updateStreams, []);

  const items = streams.map((stream) => <StreamItem key={stream} stream={stream} />);
  return (
    <Container disableGutters className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.header}>Streams:</Typography>
        </Grid>
        <Grid item xs={12}>
          <List component="div" aria-label="stream list">
            {items}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}

function StreamBrowser()
{
  return (
    <Switch>
      <Route path="/stream/:streamId">
        <StreamPlayer />
      </Route>
      <Route>
        <StreamList />
      </Route>
    </Switch>
  );
}

export default StreamBrowser;


import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CssBaseline from '@material-ui/core/CssBaseline';
import FolderIcon from '@material-ui/icons/Folder';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {
  Switch,
  Route,
  Redirect,
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import PropTypes from 'prop-types';
import Profile from './Profile';
import FileBrowser from './FileBrowser';
import StreamBrowser from './StreamBrowser';
import SinglePlayer from './SinglePlayer';
import Client from './Client';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#afd8f2',
      main: '#5289b5',
      dark: '#1f3541',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#edf2f3',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: '1',
  },
  container: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  spacer: {
    height: 'calc(22px + 64px)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    overflow: 'hidden',
    backgroundColor: '#cfe8fc',
  },
  footer: {
    padding: '16px',
  },
}));

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function BurgerMenu()
{
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const handleMenuOpen = useCallback((event) =>
  {
    setMenuAnchor(event.currentTarget);
  }, [setMenuAnchor]);

  const handleMenuClose = useCallback(() =>
  {
    setMenuAnchor(null);
  }, [setMenuAnchor]);

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        aira-controls="top-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="top-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}>
        <MenuItem component={RouterLink} onClick={handleMenuClose} to="/f">
          <ListItemIcon><FolderIcon /></ListItemIcon>
          <ListItemText primary="Fileshare" />
        </MenuItem>
        <MenuItem component={RouterLink} onClick={handleMenuClose} to="/stream">
          <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
          <ListItemText primary="Stream" />
        </MenuItem>
      </Menu>
    </div>
  );
}

function LoginDialog(props)
{
  const { open, onClose, setAuth } = props;

  const onGoogleSuccess = useCallback((response) =>
  {
    setAuth({ type: 'google', user: response });
    onClose();
  }, [setAuth, onClose]);

  const onGoogleFailure = useCallback((response) =>
  {
    console.log(response);
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Login With</DialogTitle>
      <GoogleLogin
        clientId={googleClientId}
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
        cookiePolicy="single_host_origin"
        fetchBasicProfile={false}
        scope="openid email"
      />
    </Dialog>
  );
}

LoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
};

function TopBar(props)
{
  const classes = useStyles();
  const history = useHistory();
  const { auth, setAuth } = props;
  const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);
  const [showLoginDialog, setShowLoginDialog] = React.useState(false);
  const userMenuOpen = Boolean(userMenuAnchor);

  const handleUserMenuOpen = useCallback((event) =>
  {
    setUserMenuAnchor(event.currentTarget);
  }, [setUserMenuAnchor]);

  const handleUserMenuClose = useCallback(() =>
  {
    setUserMenuAnchor(null);
  }, [setUserMenuAnchor]);

  const handleLogout = useCallback(() =>
  {
    if (!auth)
      return;
    if (auth.type === 'google')
    {
      const auth2 = window.gapi.auth2.getAuthInstance()
      auth2.signOut().then(() =>
      {
        setUserMenuAnchor(null);
        setAuth(null);
        history.push('/');
      });
    }
  }, [auth, setAuth, setUserMenuAnchor, history]);

  const handleLogin = useCallback(() =>
  {
    setShowLoginDialog(true);
  }, [setShowLoginDialog]);

  const onLoginClose = useCallback(() =>
  {
    setShowLoginDialog(false);
  }, [setShowLoginDialog]);

  useEffect(() =>
  {
    if (window.gapi.auth2 === undefined)
    {
      window.gapi.load('auth2', () =>
      {
        const auth2 = window.gapi.auth2.init({
          client_id: googleClientId,
          fetch_basic_profile: false,
          scope: 'openid email',
        });
        auth2.then(() =>
        {
          if (auth2.isSignedIn.get())
          {
            setAuth({type: 'google', user: auth2.currentUser.get()});
          }
        });
      });
    }
  }, [setAuth]);

  return (
    <AppBar position="fixed">
      <Toolbar>
        <BurgerMenu />
        <Typography variant="h6" className={classes.title}>
          LittleCodeGeek File-Share
        </Typography>
        {auth && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleUserMenuOpen}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={userMenuAnchor}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={userMenuOpen}
              onClose={handleUserMenuClose}
            >
              <MenuItem component={RouterLink} to="/profile" onClick={handleUserMenuClose}>Profile</MenuItem>
              <MenuItem component={RouterLink} to="/account" onClick={handleUserMenuClose}>My Account</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </div>
        )}
        {!auth && (
          <div>
            <Button color="inherit" onClick={handleLogin}>Login</Button>
            <LoginDialog open={showLoginDialog} onClose={onLoginClose} setAuth={setAuth} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  auth: PropTypes.object,
  setAuth: PropTypes.func.isRequired,
};

function Copyright()
{
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © littlecodegeek '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App()
{
  const classes = useStyles();
  const history = useHistory();
  const [auth, setAuth] = React.useState(null);
  const [newUser, setNewUser] = React.useState(false);

  React.useEffect(() =>
  {
    if (auth && newUser && history.location.pathname !== '/profile')
      history.push('/profile');
  });

  React.useEffect(() =>
  {
    if (!auth)
      return;
    Client.postLogin((response) =>
    {
      if (response.error)
      {
        const auth2 = window.gapi.auth2.getAuthInstance()
        auth2.signOut().then(() =>
        {
          setAuth(null);
        });
      }
      else if (response.ok === 'new_user')
      {
        setNewUser(true);
      }
      else if (response.ok === 'user_login')
      {
        setNewUser(false);
      }
    });
  }, [auth]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.content}>
          <TopBar auth={auth} setAuth={setAuth} />
          <div className={classes.spacer} />
          <main className={classes.main}>
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Switch>
                      <Route path="/stream">
                        <StreamBrowser />
                      </Route>
                      <Route path="/profile">
                        <Profile auth={auth} newUser={newUser} setNewUser={setNewUser} />
                      </Route>
                      <Route path="/f">
                        <FileBrowser auth={auth} />
                      </Route>
                      <Route path="/watch/s">
                        <SinglePlayer auth={auth} />
                      </Route>
                      <Route path="/">
                        <Redirect to="/f" />
                      </Route>
                    </Switch>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;


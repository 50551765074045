import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoPlayer from './VideoPlayer';
import Client from './Client';

const useStyles = makeStyles((theme) => ({
  topGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  bottomGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  header: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  backLink: {
    margin: 'auto',
    paddingRight: theme.spacing(2),
  },
  downloadLink: {
    margin: 'auto',
    paddingRight: theme.spacing(2),
  },
}));

function SinglePlayer()
{
  const classes = useStyles();
  const { pathname } = useLocation();
  const file = pathname.replace(/^\/watch\/s\//, '');

  const mediaFile = file.substring(file.lastIndexOf('/')+1);
  const parentFolder = "/f/" + file.substring(0, file.lastIndexOf('/'));

  const videoPlayer = useMemo(() =>
  {
    const playerOptions = {
      autoplay: false,
      controls: true,
      liveui: false,
      fluid: true,
      sources: [{
        src: `/file/${file}`,
      }],
    };
    return <VideoPlayer { ...playerOptions } />;
  }, [file]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.topGrid}>
        <Typography variant="h4" className={classes.header}>
          Media: {mediaFile}
        </Typography>
        <Link component={RouterLink} className={classes.backLink} to={parentFolder}>Back</Link>
      </Grid>
      <Grid item xs={12}>
        {videoPlayer}
      </Grid>
      <Grid item xs={12} className={classes.bottomGrid}>
        <Link href={`/file/${file}`} className={classes.downloadLink} download>
          Download
        </Link>
      </Grid>
    </Grid>
  );
}

SinglePlayer.propTypes = {
  match: PropTypes.object,
};

export default SinglePlayer;


import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Prompt,
  Link as RouterLink,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Client from './Client';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  registration: {
    paddingTop: '20pt',
    margin: '0 auto',
    maxWidth: '70%',
    width: 'fit-content',
    textAlign: 'center',
  },
  registrationTitle: {
    padding: '16pt',
  },
  registrationInfo: {
    bottom: '60pt',
  },
  extension: {
    color: '#3e80d266',
  },
});

const useStyles = makeStyles(styles);

function UserRegistration(props)
{
  const {
    setNewUser,
  } = props;
  const classes = useStyles();
  const [displayName, setDisplayName] = React.useState('');
  const [isValid, setIsValid] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);

  const handleDisplayNameChange = useCallback((event) =>
  {
    const newName = event.target.value;
    const validRegex = /^[a-zA-Z]\w{0,21}$/;
    if (newName === '' || validRegex.test(newName))
    {
      setDisplayName(newName);
      setIsValid(newName.length >= 5);
    }
    else
    {
      setShowInfo(true);
    }
  }, [setDisplayName, setIsValid, setShowInfo]);

  const handleInfoClose = useCallback((event, reason) =>
  {
    if (reason === 'clickaway')
      return;
    setShowInfo(false);
  }, [setShowInfo]);

  const handleRegister = useCallback(() =>
  {
    if (isValid)
    {
      Client.postUserRegistration(displayName, (response) =>
      {
        console.log(response);
        if (response.ok === 'registered')
        {
          setNewUser(false);
        }
        else
        {
          // TODO: handle error
        }
      });
    }
    else
    {
      setShowInfo(true);
    }
  }, [isValid, displayName, setNewUser, setShowInfo]);

  return (
    <form noValidate autoComplete="off" className={classes.registration}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.registrationTitle}>
            Complete registration by selecting a display name.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="displayName-field"
            label="Display Name"
            helperText="The display name can be changed once a week"
            variant="outlined"
            value={displayName}
            onChange={handleDisplayNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleRegister}>
            Register
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={showInfo}
        autoHideDuration={3000}
        onClose={handleInfoClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className={classes.registrationInfo}
      >
        <MuiAlert severity="info" onClose={handleInfoClose}>
          Display Name must start with a letter, contain only letters from A to Z, numbers, or underscore, and be at least 5 character long.
        </MuiAlert>
      </Snackbar>
    </form>
  );
}

UserRegistration.propTypes = {
  auth: PropTypes.object,
  setNewUser: PropTypes.func.isRequired,
};

function UserProfile(props)
{
  const { auth } = props;
  const classes = useStyles();
  const [profile, setProfile] = useState({});

  useEffect(() =>
  {
    if (auth)
      Client.getMyProfile((data) => setProfile(data.profile));
  }, [auth]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Your Profile</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Display Name</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {profile.displayName}
          <Typography component="span" className={classes.extension}>
            {profile.extension && `#${profile.extension}`}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}

UserProfile.propTypes = {
  auth: PropTypes.object,
};

function Profile(props)
{
  const {
    auth,
    newUser,
    setNewUser,
  } = props;
  const classes = useStyles();

  const registration = useMemo(() =>
  {
    if (newUser)
    {
      return <UserRegistration
        auth={auth}
        setNewUser={setNewUser}
      />;
    }
    return null;
  }, [newUser, setNewUser, auth]);

  const userProfile = useMemo(() =>
  {
    if (newUser)
      return null;
    return <UserProfile auth={auth} />
  }, [newUser, auth]);

  return (
    <>
      <Prompt when={auth && newUser} message="Your profile registration is incomplete!" />
      <Container className={classes.container}>
        {registration}
        {userProfile}
      </Container>
    </>
  );
}

Profile.propTypes = {
  auth: PropTypes.object,
  newUser: PropTypes.bool,
  setNewUser: PropTypes.func,
};

export default Profile;


import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {
  useLocation,
  Link as RouterLink
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Client from './Client';
import { useInterval } from './Utils';

const styles = (theme) => ({
  container: {
    backgroundColor: '#cfe8fc',
  },
  breadcrumbs: {
    backgroundColor: '#9fd1f9',
    paddingLeft: '14pt',
    paddingTop: '8pt',
    paddingBottom: '4pt',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    display: 'flex'
  },
  icons: {
    marginRight: theme.spacing(4),
    width: 20,
    height: 20,
  },
});

const useStyles = makeStyles(styles);
const direntType = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
});

function ContentListItem(props)
{
  const { data, path } = props;
  const icon = useMemo(() => data.type === 'file' ? <InsertDriveFileIcon /> : <FolderIcon />, [data.type]);
  let link = useMemo(() =>
  {
    const link = data.type === 'file'
      ? '/file/' + path.replace(/^\/f/, '') + '/' + data.name
      : path + '/' + data.name;
    return link.replace(/\/(\/)+/g, '/');
  }, [data, path]);
  let mediaLink = null;
  if (data.media)
  {
    let linkTo = `/watch/s/${path.replace(/^\/f/, '')}/${data.name}`.replace(/\/(\/)+/g, '/');
    mediaLink = <RouterLink to={linkTo}>
      <ListItemIcon>
        <PlayCircleFilledIcon />
      </ListItemIcon>
    </RouterLink>;
  }

  return (
    props.data.type === 'file'
      ?
      <ListItem button component="a" href={link} download>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={props.data.name} />
        {mediaLink}
      </ListItem>
      :
      <ListItem button component={RouterLink} to={link}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={props.data.name} />
      </ListItem>
  );
}

ContentListItem.propTypes = {
  data: direntType,
  path: PropTypes.string,
};

function ContentList(props)
{
  const { content } = props;
  const items = content.map((dirent) =>
    <ContentListItem
      key={dirent.name + dirent.type}
      path={props.path}
      data={dirent} />);

  return (
    <List component="div" aria-label="folder content">
      {items}
    </List>
  );
}

ContentList.propTypes = {
  content: PropTypes.arrayOf(direntType),
  path: PropTypes.string,
};

function FileBrowser(props)
{
  const { auth } = props;
  const classes = useStyles();
  const { pathname } = useLocation();
  const [data, setData] = useState({ content: [], loading: true });

  const updateContent = useCallback(() =>
  {
    const currentPath = pathname.replace(/^\/f/, '');
    Client.getList(currentPath, (data) =>
    {
      setData({ ...data, loading: false });
    });
  }, [pathname, setData]);

  useInterval(updateContent, 10000);
  useEffect(updateContent, [auth, pathname]);
  useEffect(() => setData({ content: [], loading: true }), [pathname]);

  const crumbs = useMemo(() =>
  {
    const pathnames = pathname.split('/').filter((x) => x);
    pathnames.splice(0, 1);
    return pathnames.map((value, index) =>
    {
      const last = index === pathnames.length - 1;
      const to = `/f/${pathnames.slice(0, index + 1).join('/')}`;

      return last ? (
        <Typography color="textPrimary" key={to} className={classes.link}>
          {value}
        </Typography>
      ) : (
        <Link component={RouterLink} color="inherit" to={to} key={to} className={classes.link}>
          {value}
        </Link>
      );
    });
  }, [pathname, classes.link]);

  const innerElement = useMemo(() => data.loading ?
    <Backdrop className={classes.backdrop} open={true} transitionDuration={500}>
      <CircularProgress />
    </Backdrop>
    :
    <ContentList path={pathname} content={data.content} />
  , [data.loading, pathname, data.content, classes.backdrop]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
          <Link component={RouterLink} color="inherit" to="/f" className={classes.link}>
            <ArrowForwardIosIcon className={classes.icons} />
            Share
          </Link>
          {crumbs}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        {innerElement}
      </Grid>
    </Grid>
  );
}

FileBrowser.propTypes = {
  auth: PropTypes.object,
};

export default FileBrowser;


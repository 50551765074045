import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
  useParams,
  Link as RouterLink,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoPlayer from './VideoPlayer';
import Client from './Client';

const useStyles = makeStyles((theme) => ({
  topGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex'
  },
  header: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  backLink: {
    margin: 'auto',
    paddingRight: theme.spacing(2),
  },
}));

function StreamPlayer()
{
  const classes = useStyles();
  const { streamId } = useParams();

  const videoPlayer = useMemo(() =>
  {
    const playerOptions = {
      autoplay: true,
      controls: true,
      liveui: true,
      fluid: true,
      sources: [{
        src: `${Client.streamSource}/hls/${streamId}.m3u8`,
        type: 'application/vnd.apple.mpegurl',
      }],
    };
    return <VideoPlayer { ...playerOptions } />;
  }, [streamId]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.topGrid}>
        <Typography variant="h4" className={classes.header}>
          Stream: {streamId}
        </Typography>
        <Link component={RouterLink} className={classes.backLink} to="/stream">Back</Link>
      </Grid>
      <Grid item xs={12}>
        {videoPlayer}
      </Grid>
    </Grid>
  );
}

StreamPlayer.propTypes = {
  match: PropTypes.object,
};

export default StreamPlayer;


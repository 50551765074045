const streamSource = process.env.REACT_APP_STREAM_SRC || "";

const GET = 'GET';
const POST = 'POST';

function checkStatus(response)
{
  if (response.status >= 200 && response.status < 300)
  {
    return Promise.resolve(response);
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(JSON.stringify(error));

  return Promise.reject(error);
}

function parseJSON(response)
{
  return response.json();
}

function getText(response)
{
  return response.text();
}

function fetchJson(method, url, json, cb)
{
  const text = json ? JSON.stringify(json) : undefined;
  const token = getToken();
  const headers = new Headers({ 'Accept': 'application/json', 'Accept-Charset': 'utf-8' });
  if (text)
  {
    headers.append('Content-Type', 'application/json;charset=utf-8');
    headers.append('Content-Length', text.length.toString());
  }
  if (token)
  {
    headers.append('Authorization', `Token ${token}`);
  }
  const options = {
    method: method,
    credentials: 'same-origin',
    mode: 'same-origin',
    cache: 'no-cache',
    headers: headers,
    body: text,
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(cb);
}

export function getList(path, cb)
{
  return fetchJson(POST, '/api/list', { path: path }, cb);
}

export function getStreams(cb)
{
  const options = {
    method: GET,
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Accept': 'text/xml' },
  };

  return fetch(`${streamSource}/stat`, options)
    .then(checkStatus)
    .then(getText)
    .then((text) =>
    {
      let names = [];
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(text, 'text/xml');
      let streams = xmlDoc.getElementsByTagName('stream');
      for (let i = 0; i < streams.length; ++i)
      {
        let stream = streams[i];
        let name = stream.getElementsByTagName('name')[0].textContent;
        names.push(name);
      }

      return names;
    })
    .then(cb);
}

export function postLogin(cb)
{
  return fetchJson(POST, '/api/login', null, cb);
}

export function postUserRegistration(displayName, cb)
{
  return fetchJson(POST, '/api/register', { displayName: displayName }, cb);
}

export function getMyProfile(cb)
{
  return fetchJson(GET, '/api/profile', null, cb);
}

export function getToken()
{
  try
  {
    return window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
  }
  catch (e)
  {
    return '';
  }
}

const Client = { getList, getStreams, postLogin, postUserRegistration, getMyProfile, getToken, streamSource };
export default Client;

